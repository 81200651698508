div.public-youtube-report-primary-table-body-container {
    margin-top: 30px;
    height: calc(100vh - 75px);
    padding: 0%;
  }
  
  .public-youtube-report-primary-table-body-container {
    position: relative;
    max-width: 100%;
    overflow-y: scroll;
    overflow-x: scroll;
    width: fit-content;
  }

  .public-youtube-report-primary-table-body-container thead {
    position: sticky;
    top: 0;
  }
  
  .public-youtube-report-primary-table-body-container table {
    white-space: break-spaces;
  }
  
  .youtube-report-primary-table-body-container table {
    white-space: break-spaces;
  }
  
  .public-youtube-report-primary-table-body-container thead {
    position: sticky;
    top: 0;
  }

  .public-youtube-report-primary-table-body-container thead tr th.second-column {
    position: sticky;
    left: 100px;
    z-index: 1;
  }
  
  .public-youtube-report-primary-table-body-container {
    overflow: scroll;
    height: 100%;
    width: calc(100vw - 20px);
  }
  