div.public-custom-report-primary-table-body-container {
    height: 100vh;
    padding: 0%;
  }
  
  .public-custom-report-primary-table-body-container {
    position: relative;
    max-width: 100%;
    overflow-y: scroll;
    overflow-x: scroll;
    width: fit-content;
  }

  .public-custom-report-primary-table-body-container thead {
    position: sticky;
    top: 0;
  }
  
  .public-custom-report-primary-table-body-container table {
    white-space: break-spaces;
  }
  
  .custom-report-primary-table-body-container table {
    white-space: break-spaces;
  }
  
  .public-custom-report-primary-table-body-container thead {
    position: sticky;
    top: 0;
  }

  .public-custom-report-primary-table-body-container thead tr th.second-column {
    position: sticky;
    left: 100px;
    z-index: 1;
  }
  
  .public-custom-report-primary-table-body-container {
    overflow: scroll;
    height: 100%;
    width: calc(100vw - 20px);
  }
  