.custom-report-dropdown-menu {
  max-height: 500px;
  overflow-y: scroll;
}

.no-space {
    margin: 0 !important;
    padding: 0 !important;
  }

  .level-tabs-container .nav-link{
    color: #e4e4e4;
    background-color:#e4e4e4;
  }

.level-tabs-container .nav-link.active {
    color: #ffffff;
    background-color: #000000;
    
  }

.tab-content {
    padding: 0px;
}

.date-labels {
  text-align: center;
  line-height: 150%;
  font-size: .85em;
}

.all-header-elements {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0px;
}

.entire-navbar {
  background-color: #f7f7f7;
  padding: 0%;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
}

/* .marketing-all {
  padding: 300px;
} */