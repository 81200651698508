.rowed {
  background-color: rgb(0, 0, 0);
  color: blue;
}

div.marketing-primary-table-body-container {
  height: calc(100VH - 210PX);
  padding: 0%;
  margin-top: 63px;
  margin-bottom: -20px;
  margin-left: 10px;
}

.marketing-primary-table-body-container {
  position: relative;
  max-width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
  width: fit-content;
}

.marketing-primary-table-body-container table {
  white-space: break-spaces;
}

.marketing-primary-table-body-container thead {
  position: sticky;
  top: 0;
}

.primary-table-dcontainer {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

main.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
}

tbody tr td.first-column {
  position: sticky;
  left: 0;
  z-index: 2;
}

tbody tr td.half-column {
  position: sticky;
  left: 75px;
  z-index: 2;
}

tbody tr td.second-column {
  position: sticky;
  left: 200px;
  z-index: 1;
}

thead tr th.first-column {
  position: sticky;
  left: 0;
  z-index: 2;
}

thead tr th.half-column {
  position: sticky;
  left: 75px;
  z-index: 2;
}
tbody tr.totals-footer {
  position: sticky;
  bottom: 0;
  z-index: 4;
  border-width: 1.5px;
  border-color: black;
}

.marketing-primary-table-body-container thead tr th.second-column {
  position: sticky;
  left: 75px;
  z-index: 1;
}

.marketing-primary-table-body-container {
  overflow: scroll;
  height: 100%;
  width: calc(100vw - 20px);
}


table th,
table td {
  /* padding: 8px 16px; */
  white-space: pre;
  z-index: 10;

}

table thead {
  position: sticky;
  top: 0;
  z-index: 3;
}

td.other-columns, th.other-column {
  min-width: 130px
}

.placement-tabs-container .nav-link{
  color: #e4e4e4;
  background-color:#e4e4e4;
}

.placement-tabs-container .nav-link.active {
  color: #ffffff;
  background-color: #000000;
  
}

