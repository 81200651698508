

.performance-primary-table-body-container {
 
  height: calc(100VH);
    /* overflow-y: scroll; */
  /* overflow-x: scroll; */
  width: fit-content;
  width: calc(100vw);

}

.custom-report-primary-table-body-container {
  position: relative;
  max-width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
  width: fit-content;
  height: 100VH ;
  overflow-x: scroll;
}
/* 

.snake-body-container {
  height: 300%;
  padding: 0%;
  margin-top: -10px;
  margin-bottom: 0px;
  margin-left: 10px;
}

.snake-container {
  position: relative;
  max-width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
  width: fit-content;
}

.snake-dcontainer {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
 */

